import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor() {}

  confirm(
    title: string,
    text: string,
    confirmButtonText: string = 'Yes',
    cancelButtonText: string = 'No'
  ): Promise<boolean> {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    }).then((result) => result.isConfirmed);
  }

  confirmActivateItem(
    text: string = 'Are you sure you want to activate this item?'
  ): Promise<boolean> {
    return this.confirm('Activate Item', text, 'Activate', 'Cancel');
  }

  confirmDeactivateItem(
    text: string = 'Are you sure you want to deactivate this item?'
  ): Promise<boolean> {
    return this.confirm('Deactivate Item', text, 'Deactivate', 'Cancel');
  }

  confirmDeleteItem(
    text: string = 'Do you really want to delete this item?'
  ): Promise<boolean> {
    return this.confirm('Delete Item', text, 'Yes, delete it!', 'Cancel');
  }

  confirmAcknowledgeItem(
    text: string = 'Do you really want to acknowledge this policy?'
  ): Promise<boolean> {
    return this.confirm(
      'Acknowledge Policy',
      text,
      'Yes, acknowledge',
      'Cancel'
    );
  }

  confirmRejectItem(
    text: string = 'Are you sure you want to unacknowledge this policy?'
  ): Promise<boolean> {
    return this.confirm(
      'Unacknowledge Policy',
      text,
      'Yes, Unacknowledge it!',
      'Cancel'
    );
  }

  confirmPublishItem(
    text: string = 'Do you really want to publish this item?'
  ): Promise<boolean> {
    return this.confirm('Publish Item', text, 'Yes, publish!', 'Cancel');
  }

  confirmSubmitItem(
    text: string = 'Do you really want to submit this item?'
  ): Promise<boolean> {
    return this.confirm('Submit Item', text, 'Yes, submit!', 'Cancel');
  }

  confirmResolveItem(
    text: string = 'Do you really want to resolve this item?'
  ): Promise<boolean> {
    return this.confirm('Resolve Item', text, 'Yes, resolve!', 'Cancel');
  }

  confirmReopenItem(
    text: string = 'Do you really want to reopen this item?'
  ): Promise<boolean> {
    return this.confirm('Reopen Item', text, 'Yes, reopen!', 'Cancel');
  }

  confirmGenerateItem(
    text: string = 'Do you really want to generate this item?'
  ): Promise<boolean> {
    return this.confirm('Generate Item', text, 'Yes, generate!', 'Cancel');
  }

  confirmCompleteItem(
    text: string = 'Do you really want to complete this item?'
  ): Promise<boolean> {
    return this.confirm('Complete Item', text, 'Yes, complete!', 'Cancel');
  }
  confirmSentItem(
    text: string = 'Do you really want to send this item?'
  ): Promise<boolean> {
    return this.confirm('Sent Item', text, 'Yes, sent!', 'Cancel');
  }
}
